<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Change Password
            </div>

          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    label="Old Password"
                    v-model="user.oldPassword"
                  type="password"
                    
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    class="purple-input"
                    label="New Password"
                    type="password"
                    v-model="user.newPassword"
                  />
                </v-col>

               



                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="saveChanges"
                  >
                    Save Changes
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

   
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data(){
      return{
        user:{

        }
      }
    },
    methods:{
      saveChanges(){
        this.$Auth.changePassword(this.user).then(data=>{
          console.log(data.data);
          this.$toastr.success("Password Changed Succesfully");
          this.user = {}
          this.$router.push({path:'/'})
        }).catch(error=>{
          if(error.response){
            this.$toastr.error(error.response.data.message);
          }else{
            this.$toastr.error("Something Went Wrong");
          }
          

        })
      }
    },
   


  }
</script>
